<template>
    <div>
        <b-row  v-if="unpairedUsers">
            <b-col cols="12" md="4">
                <b-card no-body class="card-employee-task">
                    <b-card-header>
                        <b-card-title>Discord Users</b-card-title>
                    </b-card-header>

                    <!-- body -->
                    <b-card-body>
                        <div id="max-h-scroll">
                            <div v-for="discorduser in unpairedUsers.discord" :key="discorduser.id"
                                class="employee-task d-flex justify-content-between align-items-center mb-1">
                                <b-media no-body>
                                    <b-media-aside class="mr-75">
                                        <b-avatar rounded size="42" :src="discorduser.image"
                                            :text="avatarText(discorduser.name)" />
                                    </b-media-aside>

                                    <b-media-body class="my-auto">
                                        <h6 class="mb-0">
                                            {{ discorduser.name }}
                                        </h6>
                                        <small>{{ discorduser.id }}</small>
                                    </b-media-body>
                                </b-media>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card no-body class="card-employee-task">
                    <b-card-header>
                        <b-card-title>Bitbucket Users</b-card-title>
                    </b-card-header>

                    <!-- body -->
                    <b-card-body>
                        <div id="max-h-scroll">
                            <div v-for="bitbucketuser in unpairedUsers.bitbucket" :key="bitbucketuser.id"
                                class="employee-task d-flex justify-content-between align-items-center mb-1">
                                <b-media no-body>
                                    <b-media-aside class="mr-75">
                                        <b-avatar rounded size="42" :src="bitbucketuser.image"
                                            :text="avatarText(bitbucketuser.name)" />
                                    </b-media-aside>

                                    <b-media-body class="my-auto">
                                        <h6 class="mb-0">
                                            {{ bitbucketuser.name }}
                                        </h6>
                                        <small>{{ bitbucketuser.id }}</small>
                                    </b-media-body>
                                </b-media>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card no-body class="card-employee-task">
                    <b-card-header>
                        <b-card-title>Asana Users</b-card-title>
                    </b-card-header>

                    <!-- body -->
                    <b-card-body>
                        <div id="max-h-scroll">
                            <div v-for="asanaUser in unpairedUsers.asana" :key="asanaUser.id"
                                class="employee-task d-flex justify-content-between align-items-center mb-1">
                                <b-media no-body>
                                    <b-media-aside class="mr-75">
                                        <b-avatar rounded size="42" :src="asanaUser.image"
                                            :text="avatarText(asanaUser.name)" />
                                    </b-media-aside>

                                    <b-media-body class="my-auto">
                                        <h6 class="mb-0">
                                            {{ asanaUser.name }}
                                        </h6>
                                        <small>{{ asanaUser.asana_user_id }}</small>
                                    </b-media-body>
                                </b-media>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BCard,
        BCardText,
        BCol,
        BRow,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'
    import useUserListApi from '@/composables/useUserListApi'
    import { avatarText } from '@core/utils/filter'

    export default {
        data: function () {
            return {

            }
        },
        components: {
            BCard,
            BCardText,
            BCol,
            BRow,
            BCardTitle,
            BCardHeader,
            BCardBody,
            BMedia,
            BMediaAside,
            BAvatar,
            BMediaBody,
        },
        setup() {
            const {
                fetchUnpairedUsers,
                unpairedUsers,
            } = useUserListApi()

            return {
                fetchUnpairedUsers,
                unpairedUsers,
                avatarText
            }
        },
        mounted(){
            this.fetchUnpairedUsers()
        }
    }
</script>

<style>
#max-h-scroll::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
#max-h-scroll::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#max-h-scroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
#max-h-scroll{
	overflow: auto;
	max-height: 70vh;
    padding-right: 10px;
    padding-top: 10px;
}
</style>
